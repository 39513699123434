/* eslint-disable @next/next/no-img-element */
import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { useLayoutStore } from "@store/layoutStore";
import { useEffect, useRef, useState } from "react";
import { shallow } from "zustand/shallow";
import MenuItem from "./MenuItem";

export default function Menu() {
  const { getLangLabel } = useWhiteLabelLan();
  const { menu } = useLayoutStore(
    (state) => ({
      menu: state.externalConfig?.menu,
    }),
    shallow
  );
  const [show, setShow] = useState(false);
  const ref = useRef<any>(null);

  const handleToggle = () => {
    setShow(!show);
  };

  // useffect detect click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      className="tw-flex tw-text-center tw-border-r-2 tw-pr-4 tw-justify-center tw-align-middle tw-items-center"
    >
      <div className="tw-relative">
        <button
          id="dropdownDefaultButtonMenuNav"
          data-dropdown-toggle="dropdown_nav_menu"
          className={classNames(
            "hover:tw-bg-inspira-purple-100  tw-text-gray-700 ",
            "focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-1 tw-py-1",
            "tw-text-center tw-inline-flex tw-items-center tw-justify-center tw-align-middle",
            "tw-border-none md:tw-border md:tw-border-gray-400 tw-w-[40px] md:tw-min-w-[7rem]"
          )}
          type="button"
          // onMouseEnter={handleShow}
          // onMouseLeave={handleHide}
          onClick={() => handleToggle()}
        >
          <span className="tw-hidden md:tw-inline-flex">
            {getLangLabel("Explore now")}
          </span>
          <img
            src="/images/general/hamburguer.svg"
            alt="menu"
            className="tw-w-[40px] tw-h-auto md:tw-hidden"
          />
        </button>

        <div
          id="dropdown_nav_menu"
          className={classNames(
            "tw-absolute tw-z-10 tw-border tw-bg-white tw-divide-y tw-divide-gray-300 tw-rounded-xl tw-shadow-2xl",
            show ? "" : "tw-hidden",
            "tw-absolute -tw-right-[2rem] tw-top-10 tw-mx-auto tw-w-[15rem] lg:tw-w-[14rem] tw-will-change-auto tw-transition-all tw-duration-300 tw-ease-in-out",
            "tw-flex tw-justify-center tw-items-center tw-flex-col tw-align-middle tw-pt-4 tw-px-1"
          )}
        >
          {menu?.sections?.map((menuGroup, index) => (
            <div
              key={`menu-group-${index}`}
              className={classNames(
                "tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-col tw-align-middle tw-text-left tw-text-[12.5px] tw-px-2 ",
                index > 0 ? "tw-pt-2" : ""
              )}
            >
              <label className=" tw-px-4 tw-text-left tw-w-full  pb-2">
                {menuGroup.name}
              </label>
              <ul
                className=" tw-p-0 tw-text-gray-700 tw-w-full"
                tw-
                aria-labelledby="dropdownDefaultButtonMenuNav"
              >
                {menuGroup.items?.map((item, index2) => {
                  const currentKey = `menu-item-${index2}`;
                  return (
                    <li key={currentKey}>
                      <MenuItem item={item} />
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
