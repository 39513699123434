/* eslint-disable @next/next/no-img-element */
import { useAuth } from "@contexts/AuthContext";
import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { useMenuItemsAction } from "@hooks/useMenuItemsAction";
import { useMain } from "@contexts/mainContext";
import Link from "next/link";

export default function MenuItem({ item }: { item: ItemMenu }) {
  const { getLangLabel } = useWhiteLabelLan();
  const { isLogged } = useAuth();
  const { getAction } = useMenuItemsAction();
  const { profileTabSelected } = useMain();
  const isCurrentKeySelected = (key: string) => {
    // check current key is the same in the path name
    if (typeof window !== "undefined") {
      const pathName = window.location.pathname;

      if (pathName == "/user-details") {
        const option_id = String(item?.id || "");
        const isSelected: boolean = option_id == profileTabSelected;
        return isSelected;
      } else if (pathName == key) {
        return true;
      }
    }
  };

  if (item.onlySigned && !isLogged) {
    return null;
  }

  if (item.onlySignout && isLogged) {
    return null;
  }

  if (item.action) {
    return (
      <button
        onClick={() => getAction(item.action || "")}
        className={classNames(
          "tw-w-full",
          "tw-px-4 tw-rounded-full tw-no-underline tw-text-gray-700 hover:tw-text-inspira-purple-500",
          " hover:tw-bg-inspira-purple-100 tw-flex tw-flex-row tw-gap-4 tw-my-1 tw-py-1",
          isCurrentKeySelected(item?.url || "#")
            ? "tw-text-white tw-bg-inspira-purple-100"
            : ""
        )}
      >
        {item?.images?.on && item?.images?.off && (
          <>
            <img
              src={item.images.on}
              alt="menu"
              width={20}
              height={20}
              className={classNames(
                isCurrentKeySelected(item?.url || "#") ? "" : "tw-hidden"
              )}
            />
            <img
              src={item.images.off}
              alt="menu"
              width={20}
              height={20}
              className={classNames(
                isCurrentKeySelected(item?.url || "#") ? "tw-hidden" : ""
              )}
            />
          </>
        )}
        {getLangLabel(item.label)}
      </button>
    );
  }

  return (
    <Link href={item.url || "#"}>
      <a
        className={classNames(
          " tw-px-4 tw-rounded-full tw-no-underline tw-text-gray-700 hover:tw-text-inspira-purple-500 hover:tw-bg-inspira-purple-100 tw-flex tw-flex-row tw-gap-4 tw-my-1 tw-py-1",
          isCurrentKeySelected(item?.url || "#")
            ? "tw-text-white tw-bg-inspira-purple-100"
            : ""
        )}
      >
        {item?.images?.on && item?.images?.off && (
          <>
            <img
              src={item.images.on}
              alt="menu"
              width={20}
              height={20}
              className={classNames(
                isCurrentKeySelected(item?.url || "#") ? "" : "tw-hidden"
              )}
            />
            <img
              src={item.images.off}
              alt="menu"
              width={20}
              height={20}
              className={classNames(
                isCurrentKeySelected(item?.url || "#") ? "tw-hidden" : ""
              )}
            />
          </>
        )}
        {getLangLabel(item.label)}
      </a>
    </Link>
  );
}
